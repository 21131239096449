@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

.theme-bg {
  background-color: #e39d6b !important;
}
.theme-text {
  color: #e39d6b !important;
}

body.modal-open {
  overflow: hidden;
}

.loader {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-sizing: border-box;
  border-top: 5px solid orange !important;
  border-left: 5px solid orange;
  border-right: 5px solid #ff00;
  animation: loader 0.7s infinite linear;
}

@keyframes loader {
  to {
    transform: rotate(360deg);
  }
}

/* Scrollbar - Student Management */
/* Hide the default scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Style for the custom scrollbar track */
.custom-scrollbar {
  scrollbar-width: thin; /* "auto" or "thin" for Firefox */
  scrollbar-color: rgba(155, 155, 155, 0.3) transparent; /* thumb and track color */
}

/* Style for the custom scrollbar thumb */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.3); /* thumb color */
  border-radius: 10px; /* rounded corners */
  border: 3px solid transparent; /* border to create padding */
}

/* Hover effect for the custom scrollbar thumb */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(155, 155, 155, 0.5); /* lighter thumb color on hover */
}

/* Style for the custom scrollbar track */
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent; /* track color */
}

/* Phone Input Styles */
.form-control {
  @apply !mt-1 !w-full rounded !border !border-gray-300 !border-opacity-50 !bg-gray-50 !py-5;
  @apply focus:outline-none focus:ring;
}

.flag-dropdown {
  @apply !border !border-gray-300 !border-opacity-50;
}

/* .radix-themes {
  --default-font-family: var(--font-inter) !important;
} */
